import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import BlogPostExcerpt from '../components/blog-post-excerpt'
import SEO from "../components/seo";

export const query = graphql
`
query {
  allMarkdownRemark(
    sort: {fields: [frontmatter___date], order: DESC}
    filter: {frontmatter: {published: {eq: true}}}
    limit: 5
  ) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        tableOfContents
        frontmatter {
          date(formatString: "MMM DD, YYYY")
          author
          title
          slug
          tags
        }
      }
    }
  }
}
`

const IndexPage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout> 
      <SEO />
      <div className='flex flex-col m-4'>
        <h1 className='font-semibold text-3xl text-center text-white'>Latest Blog Posts</h1>
      </div>

      <div className='flex flex-col'>
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <Link key={post.frontmatter.slug} to={post.frontmatter.slug}>
                <BlogPostExcerpt post={post}></BlogPostExcerpt>
              </Link>
            )
          })}
      </div>

      <div className='flex flex-col m-4'>
        <p className='font-semibold text-lg text-center text-white hover:underline'>
          <Link to="/posts" >
            See All Posts
          </Link>
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage
